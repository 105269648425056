$break-xsmall: 320px;
$break-small: 414px;
$break-md: 600px;

.container {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-flow: column nowrap;
	height: 100%;
	width: 100%;
	max-width: 100vw;
	max-height: 100vh;
	min-width: 100vw;
	min-height: 100vh;
}
.textFieldInput{
	border: 2px solid black;
	margin: 5px;
	
}
.nav {
		display: flex;
		width: 90%;
		height: 12%;
		max-width: 90vw;
		max-height: 12vh;
		min-width: 90vw;
		min-height: 12vh;
		justify-content: space-between;

		&__logo {
			display: flex;
			max-height: 160px;
			min-height: 60px;

			@media screen and (max-width: $break-xsmall) {
				max-height: 100px;
				margin-left: -30px;
				margin-top: -30px;
}
@media screen and (max-width: $break-small) {
	max-height: 100px;
	margin-left: -30px;
	margin-top: -30px;
}

		}

		&__tabs {
			display: flex;
			justify-content: flex-end;

			& > * {
				align-self: center;
				height: 40px;
			}
		}
	}

.btnClose {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	flex-flow: row wrap;
}

.content {
		display: flex;
		justify-content: space-around;
		width: 100%;
		height: 77% ;
		max-width: 100vw;
		max-height: 77vh;
		min-width: 100vw;
		min-height: 77vh;

		&__left {
			display: flex;
			width: 50%;
			justify-content: flex-start;
			flex-flow: column nowrap;
			align-items: flex-start;
			margin-left: 5%;
			margin-right: 50px;
			margin-top: 7%;

			&__btn {
				width: 160px;
				height: 55px;
			}
		}

		&__pointer {
			color:'gray'; 
			font-size:24;
			 margin-top:8;
		}

		&__video {
			display: flex;
			width: 100vw;
			max-width: 900px;
			justify-content: center;
			flex-flow: column nowrap;
			align-items: center;
			height: 80vh;
			max-height: 480px;
			margin: auto;
			padding-right: 5%;
			padding-left: 5%;

			@media screen and (max-width: $break-small) {
				max-height: 350px;

			}

		}
		&__centerForm {
			display: flex;
			width: 50%;
			justify-content: center;
			flex-flow: column nowrap;
			align-items: center;
			margin-left: 5%;
			margin-right: 50px;
			margin-top: 7%;
		}

		&__center{
			display: flex;
			width: 100%;
			flex-flow: column nowrap;
			align-items: center;
			max-height: 80vh;


			&__input {
				display: flex;
				min-width: 55%;
				max-width: 55%;
				flex-flow: column nowrap;
				justify-content: center;

			}

		

			&__aboutUs {
				display: flex;
				flex-flow: row wrap;
				justify-content: center;

				max-width: 55%;
				max-height: 80%;
				color:white;
				justify-content: center;
				padding: 5%;



			}
		}

		&__right {
			display: flex;
			width: 50%;
			margin-top: 5.3%;
			margin-left: -70px;
			justify-content: flex-start;
			flex-flow: column nowrap;
			align-items: center;
		}

		&__admin {
			display: flex;
			width: 100%;
			flex-flow: row wrap;
			align-items: center;
			max-height: 80vh;
			max-height: 80vh;
			justify-content: space-around;
		}
}


.aboutTitle {
	margin: 0;
	font-weight: 350;
	line-height: 1.2;
	font-size: 38px;
	@media screen and (max-width: $break-md) {

		font-size: 26px;

}
	@media screen and (max-width: $break-small) {

		font-size: 22px;

}
	@media screen and (max-width: $break-xsmall) {
		font-weight: 350;
		line-height: 1.2;
		font-size: 16px;

}

}

.aboutP {
	font-size: 20px;
	line-height: 2;
	font-weight: 10;

	@media screen and (max-width: $break-md) {

	font-size: 18px;
	line-height: 1.7;

}
@media screen and (max-width: $break-small) {

font-size: 13px;


}
	@media screen and (max-width: $break-xsmall) {
		font-size: 12px;
		line-height: 1.5;

}


}

.thxP {
	margin: 5%;
	font-weight: 200;
	font-size: 25px;

	@media screen and (max-width: $break-small) {
		font-size: 14px;


}
}
.footer {
	display: flex;
	background-color: #757575;
	align-items: center;
	justify-content: space-between;
	height: 14vh;

		& > * {
			display: flex;
			flex-flow: row nowrap;
			margin: 2%;
	}
}

h1, h2, p {
	font-family: "Montserrat", sans-serif;
}

h1 {
  font-weight: 600;
	font-size: 50px;
	color: white;
}
.AppLogo {
  animation: App-logo-spin infinite 20s linear;
}

.logoImg {
	animation: App-logo-spin infinite 20s linear;
		min-width: 160px;


}

.main-nav a {
	display: flex;
  font-size: 14px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  padding: 1em;
  transition: .9s;
	-webkit-transition: .9s;
	-moz-transition: .9s;
	-o-transition: .9s;
  border-radius: 5px;
	background-color: #424242;
	margin-right: 25px;
	width: 100px;
	height: 16px;
	text-decoration: none;
	font-family: "Montserrat", sans-serif;

}

.main-nav a:hover,
.main-nav .active {

  background: #E23E81;
}

.mobile-nav a:hover,
.mobile-nav .active {

  color: #E23E81;
}
.mobile-nav a {
	display: flex;
  	font-size: 14px;
  	color: #424242;
	width: 200px;
	min-width: 200px;
  	text-transform: uppercase;
	text-decoration: none;
	font-family: "Montserrat", sans-serif;

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




